import { gliderAutoplay } from 'glider-autoplay';
// require('glider-autoplay/index');
import * as bootstrap from 'bootstrap';
require('glider-js/glider.min');
const noUiSlider = require('nouislider/dist/nouislider.min');
// eslint-disable-next-line no-undef
window.noUiSlider = noUiSlider;
import 'sylius/bundle/ShopBundle/Resources/private/js/shim/shim-jquery';
import 'sylius/ui-resources/js/app';
import 'sylius/bundle/ShopBundle/Resources/private/js/app';
import '../../vendor/bitbag/elasticsearch-plugin/src/Resources/assets/shop/scss/main.scss';
import ElasticSearchAutocomplete from './js/elasticSearchAutocomplete';
new ElasticSearchAutocomplete().init();
import './js/wishlist';
import './js/forms_validation';
import './js/validationMethods';
import './scss/app.scss';
import $ from "jquery";
import 'vanilla-cookieconsent/dist/cookieconsent';

$.fn.extend({
  addToCart() {
    const element = this;
    const url = $(element).attr('action');
    // const redirectUrl = $(element).data('redirect');
    const validationElement = $('#sylius-cart-validation-error');
    const modal = new bootstrap.Modal(document.getElementById('after-add-to-cart-modal'));

    element.api({
      method: 'POST',
      on: 'submit',
      cache: false,
      url,
      beforeSend(settings) {
        /* eslint-disable-next-line no-param-reassign */
        settings.data = element.serialize();

        return settings;
      },
      onSuccess() {
        validationElement.addClass('hidden');
        modal.show();
      },
      onFailure(response) {
        validationElement.removeClass('hidden');
        let validationMessage = '';

        Object.entries(response.errors.errors).forEach(([, message]) => {
          validationMessage += message;
        });
        validationElement.html(validationMessage);
        $(element).removeClass('loading');
      },
    });
  },
});


window.addEventListener('DOMContentLoaded', () => {
  // obtain plugin
  // eslint-disable-next-line no-undef
  const cc = initCookieConsent();

  // run plugin with your configuration
  cc.run({
    current_lang: 'pl',
    autoclear_cookies: true,
    page_scripts: true,
    gui_options: {
      consent_modal: {
        layout: 'cloud',
      },
    },

    languages: {
      pl: {
        consent_modal: {
          description: 'Informujemy, iż w celu realizacji usług dostępnych w naszym sklepie, optymalizacji jego treści oraz dostosowania sklepu do Państwa indywidualnych  potrzeb  korzystamy z  informacji  zapisanych za pomocą plików cookies na urządzeniach końcowych użytkowników.  Pliki  cookies można kontrolować  za pomocą ustawień  swojej przeglądarki  internetowej.  Dalsze  korzystanie  z naszego sklepu internetowego, bez zmiany ustawień przeglądarki internetowej oznacza, iż użytkownik akceptuje stosowanie plików cookies. Więcej informacji zawartych jest w <a href="/pl_PL/strona/polityka-prywatnosci">polityką prywatności sklepu</a> sklepu.',
          primary_btn: {
            text: 'Akceptuję',
            role: 'accept_all',
          },
          // secondary_btn: {
          //   text: 'Odrzuć',
          //   role: 'accept_necessary',
          // },
        },
        settings_modal: {
          title: 'Preferencje cookie',
          save_settings_btn: 'Zapisz ustawienia',
          accept_all_btn: 'Akceptuj wszystkie',
          reject_all_btn: 'Odrzuć wszystkie',
          close_btn_label: 'Zamknij',
          cookie_table_headers: [],
          blocks: [],
        },
      },
    },
  });
  const quantityElem = document.querySelectorAll('.quantity');
  if (typeof (quantityElem) !== 'undefined' && quantityElem !== null) {
    quantityElem.forEach((quantity) => {
      const minus = quantity.querySelector('.minus');
      const plus = quantity.querySelector('.plus');
      const input = quantity.querySelector('input[type="number"]');
      minus.addEventListener('click', () => {
        const inputVal = parseInt(input.value, 10);
        const min = input.getAttribute('min');
        if (input.hasAttribute('min')) {
          if ((inputVal - 1) >= min) {
            input.setAttribute('value', (inputVal - 1));
          }
        } else {
          input.setAttribute('value', (inputVal - 1));
        }
      });
      plus.addEventListener('click', () => {
        const inputVal = parseInt(input.value, 10);
        const max = input.getAttribute('max');
        if (input.hasAttribute('max')) {
          if ((inputVal + 1) <= max) {
            input.setAttribute('value', (inputVal + 1));
          }
        } else {
          input.setAttribute('value', (inputVal + 1));
        }
      });
    });
  }

  const fileElem = document.querySelectorAll('input[type="file"]');
  if (typeof (fileElem) !== 'undefined' && fileElem !== null) {
    fileElem.forEach((file) => {
      file.addEventListener('change', () => {
        const fileNameElem = file.parentElement.querySelector('.file-name');
        if (file.files.length) {
          fileNameElem.innerHTML = file.files[0].name;
        } else {
          fileNameElem.innerHTML = 'Załącznik (opcjonalne)';
        }
      });
    });
  }

  const references = document.querySelectorAll('.single-reference');
  if (typeof (references) !== 'undefined' && references !== null) {
    references.forEach((reference) => {
      const showBtn = reference.querySelector('.btn');
      const referenceModal = document.getElementById('referenceModal');
      if (typeof (referenceModal) !== 'undefined' && referenceModal !== null) {
        showBtn.addEventListener('click', () => {
          referenceModal.querySelector('.reference-image').src = showBtn.dataset.fullref;
        });
      }
    });
  }
  function createSelectFill(select, fieldSelector, transformKey) {
    select.addEventListener('change', (event) => {
      const selectedOption = event.target.options[event.target.selectedIndex];
      Object.keys(selectedOption.dataset).forEach((key) => {
        if (typeof transformKey === 'function') {
          // eslint-disable-next-line no-param-reassign
          key = transformKey(key);
        }
        const inputElement = document.querySelector(fieldSelector.replace('__key__', key));
        if (inputElement !== undefined && inputElement !== 'undefined' && inputElement) {
          inputElement.value = selectedOption.dataset[key];
        }
      });
    });
  }
  const transformKeyForAddressForm = (key) => {
    if (key === 'postCode') {
      return 'postcode';
    }
    return key;
  };
  const addressFillSelects = document.querySelectorAll('select[name="selectAddress"]');
  if (addressFillSelects && addressFillSelects[0]) {
    createSelectFill(addressFillSelects[0], '[name="sylius_checkout_address[billingAddress][__key__]"]', transformKeyForAddressForm);
  }
  if (addressFillSelects && addressFillSelects[1]) {
    createSelectFill(addressFillSelects[1], '[name="sylius_checkout_address[shippingAddress][__key__]"]', transformKeyForAddressForm);
  }

  const sidebar = document.getElementById('sidebarMain');
  if (typeof (sidebar) !== 'undefined' && sidebar !== null) {
    window.addEventListener('click', (e) => {
      if (!sidebar.contains(e.target)) {
        const showedCollapse = document.querySelector('#sidebarMain .single-item:not(.collapsed)');
        if (typeof (showedCollapse) !== 'undefined' && showedCollapse !== null) {
          showedCollapse.click();
        }
      }
    });
  }

  const homepageSlider = document.getElementById('homepageSlider');
  if (homepageSlider) {
    gliderAutoplay(
      new Glider(homepageSlider, {
        slidesToShow: 1,
        slidesToScroll: 1,
        duration: 0.5,
        dots: '#dots',
        arrows: true,
        draggable: true,
        rewind: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              draggable: false,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              draggable: false,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              draggable: true,
            },
          },
        ],
      }),
      {
        interval: 5000,
        pausable: false,
      },
    );
  }
});

$(document)
  .on('click', '#sidebarMain a', () => {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  })
  .ready(() => {
    $('#attributes > .field .field input[type="checkbox"]').each(function () {
      if ($(this).is(':checked')) {
        const collapse = new bootstrap.Collapse($(this).closest('.collapse').get(0));
        collapse.show();
      }
    });
  });
