const jqueryValidation = require('jquery-validation');

$('form[name="ask_special_price"]').each( function () {
  $(this).validate({
    focusInvalid: false,
    invalidHandler: function(form, validator) {
      if (!validator.numberOfInvalids())
        return;
      $('html, body').animate({
        scrollTop: $(validator.errorList[0].element).offset().top -200
      }, 500);
    },
    rules: {
      "ask_special_price[firstName]": {
        required: true,
      },
      "ask_special_price[lastName]": {
        required: true,
      },
      "ask_special_price[email]": {
        required: true,
      },
      "ask_special_price[phone]": {
        required: true,
      },
      "ask_special_price[company]": {
        required: false,
      },
      "ask_special_price[nip]": {
        required: false,
      },
    },
    errorPlacement: function (error, element) {
      if(
        element.attr("type") === "text"
      ) {
        error.appendTo(element.closest(".field"));
      } else {
        error.insertAfter(element);
      }
    },
    success: function (label, element) {
      const $element = $(element);
      if(
        $element.attr("type") === "text"
      ) {
        $element.closest(".field").find('.error').remove();
      } else {
        $element.next('.error').remove();
      }
    }
  });
});
