jQuery.extend(jQuery.validator.messages, {
  required: "To pole jest wymagane",
  remote: "Proszę naprawić to pole",
  email: "Podaj prawidłowy adres e-mail.",
  url: "Podaj prawidłowy URL.",
  date: "Podaj prawdiłową datę.",
  dateISO: "Podaj prawidłową datę (ISO).",
  number: "Podaj prawidłowy numer.",
  digits: "Podaj prawidłową liczbę.",
  creditcard: "Podaj prawidłowy numer karty kredytowej.",
  equalTo: "Powtórz tą samą wartość.",
  accept: "Podaj wartość z prawidłowym rozszerzeniem.",
  maxlength: jQuery.validator.format(
    "Wartość powinna mieć nie więcej niż {0} znaków."
  ),
  minlength: jQuery.validator.format(
    "Wartość powinna mieć co najmniej {0} znaków."
  ),
  rangelength: jQuery.validator.format(
    "Wartość powinna mieć pomiędzy {0} a {1} znaków."
  ),
  range: jQuery.validator.format("Podaj wartość pomiędzy {0} a {1}."),
  max: jQuery.validator.format("Podaj wartość mniejszą lub równą {0}."),
  min: jQuery.validator.format("Podaj wartość większą lub równą {0}."),
});

jQuery.validator.setDefaults({
  onfocusout: function (element) {
    $(element).valid();
  },
});
