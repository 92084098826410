import { CreateCopyToWishlistsListModal } from './CopyToWishlistModal';
import { AddWishlistModal } from '../../../vendor/bitbag/wishlist-plugin/src/Resources/assets/shop/js/addAnotherWishlistModal';
import { WishlistMainCheckboxUpdater } from './wishlistMainCheckboxupdater';
import '../../../vendor/bitbag/wishlist-plugin/src/Resources/assets/shop/scss/main.scss';


const copyToWishlistBtn = document.querySelector('[data-bb-wl-list-modal-target="choose-wishlist-button"]');
const mainCheckboxlistener = document.querySelector('#toggle-checkboxes');
const addWishlistBtn = document.querySelector('[data-bb-wishlist-add="add-another-wishlist"]');

const setAddWishlistModal = () => {
  addWishlistBtn.addEventListener("click", (e) => {
    e.preventDefault();
    new AddWishlistModal(
      {
        headerTitle: 'Wpisz nazwę nowej listy życzeń',
        cancelText: 'Anuluj',
        performText: 'Utwórz',
      },
      {
        cancelAction: () => {},
        performAction: async () => {
          const form = document.querySelector("#create_new_wishlist");
          const url = "/wishlists/create";
          const formData = new FormData(form);
          const csrfToken =
            document.querySelector("[data-bb-csrf]").dataset.bbCsrf;

          const headers = new Headers({
            'X-CSRF-TOKEN': csrfToken,
          });

          const requestConfig = {
            method: 'POST',
            headers,
            body: formData,
          };

          try {
            const response = await fetch(url, requestConfig);
            const data = await response.json();
          } catch (error) {
            console.error(error);
          } finally {
            location.reload();
          }
        },
      },
    ).init();
  });
};

const setCopyWishlistModal = () => {
  copyToWishlistBtn.addEventListener('click', (e) => {
    e.preventDefault();

    new CreateCopyToWishlistsListModal(
      {
        headerTitle: 'Wybierz listę życzeń do której wybrane produkty mają zostać skopiowane',
        cancelText: 'Anuluj',
        performText: 'Kopiuj',
      },
      {
        cancelAction: () => {},
        performAction: async () => {
          const form = document.querySelector('#wishlist_form');
          const formData = new FormData(form);
          const wishlistsBtn = document.querySelector('[data-bb-wishlist-enter]');
          const chosenWishlist = document.querySelector('.copy-confirmation-modal select').value;
          const url = `${window.location.href}/copy/${chosenWishlist}`;
          const csrfToken = document.querySelector("[data-bb-csrf]").dataset.bbCsrf;
          const headers = new Headers({
            'X-CSRF-TOKEN': csrfToken
          });

          const requestConfig = {
            method: 'POST',
            headers: headers,
            body: formData
          };

          try {
            const response = await fetch(url, requestConfig);
            const data = await response.json();
            wishlistsBtn.classList.add('bb-copy-to-wishlist-sukces')
            setTimeout(() => {
              wishlistsBtn.classList.remove('bb-copy-to-wishlist-sukces')
            }, 900);
          } catch (error) {
            wishlistsBtn.classList.add('bb-copy-to-wishlist-faliure')
            setTimeout(() => {
              wishlistsBtn.classList.remove('bb-copy-to-wishlist-faliure')
            }, 900);
            console.error(error);
          } finally {
            location.reload();
          }
        },
      }
    ).init();
  });

};

const turnOnListener = () => {
  if (!addWishlistBtn) {
    return;
  }
  setAddWishlistModal();
};
turnOnListener();

const turnOnCopyListener = () => {
  if (!copyToWishlistBtn) {
    return;
  }
  setCopyWishlistModal();
};

turnOnCopyListener();

const turnOnCheckboxListener = () =>{
  if (!mainCheckboxlistener) {
    return;
  }
  new WishlistMainCheckboxUpdater().init();
};

turnOnCheckboxListener();
